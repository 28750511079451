import { default as Chart } from './charts.js';

export default function createCharts() {
    var finances = CZ_DATA["financial_history"];
    var ctx = document.getElementById('change-over-time');
    if (!finances || !ctx) return;
    var prefix = "£";

    return new Chart(ctx, {
        type: 'line',
        data: {
            labels: finances.map((x) => x.fye.slice(0, 4)),
            datasets: [{
                label: 'Income',
                data: finances.map((x) => x.income),
                fill: false,
                lineTension: 0,
                borderColor: '#4A3CE6',
                pointRadius: 1,
                hitRadius: 5,
            }, {
                label: 'Spending',
                data: finances.map((x) => x.spending),
                fill: false,
                lineTension: 0,
                borderColor: '#F6306C',
                pointRadius: 1,
                hitRadius: 5,
            }]
        },
        options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        boxHeight: 1,
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (value, index, values) {
                            if (value > 1000000000) {
                                return prefix + (value / 1000000000) + "bn";
                            } else if (value > 1000000) {
                                return prefix + (value / 1000000) + "m";
                            } else if (value > 1000) {
                                return prefix + (value / 1000) + "k";
                            }
                            return prefix + value;
                        },
                        count: 2,
                    },
                    grid: {
                        display: false,
                    }
                },
                x: {
                    /*type: 'time',
                    time: {
                        unit: 'month',
                        displayFormats: {
                            month: 'YYYY',
                        }
                    },*/
                    ticks: {
                        stepSize: 24,
                    },
                    distribution: 'series',
                    grid: {
                        display: false,
                    }
                }
            }
        }
    });
}